import { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// MUI
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import UserInformation from 'components/shared/Form/UserInformation';
import OrderItems from 'components/shared/Form/OrderItems';
import ManualAdjustments from 'components/shared/Form/ManualAdjustments';
import BillingAddress from 'components/shared/Form/BillingAddress';
import DeliveryAddress from 'components/shared/Form/DeliveryAddress';
import { useUser } from 'contexts/userContext';
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import { putRequestQuery } from 'api/apiRequest';
import Form from 'components/shared/Form';
import { useAppContext } from 'contexts/app-context';
import capitalise from 'utils/capitalise';
import toApiValuesCustom from './to-api-values-custom';
import AddOrderProductGroupParts from './AddOrderProductGroupParts';

const AddOrderForm = (props) => {
  const {
    apiErrors,
    fields,
    formValues,
    getApiError,
    resource,
    setApiErrors,
    validationSchema,
  } = props;

  const [basket, setBasket] = useState(resource);
  const entityName = 'order';
  const [convertToOrder, setConvertToOrder] = useState(false);
  // Field values
  const [searchGroupProducts, setSearchGroupProducts] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [productOrProductVariantIri, setProductOrProductVariantIri] = useState(null);
  const [productOrProductVariantChoices, setProductOrProductVariantChoices] = useState([]);
  // Modal state
  const [productGroupPartsModalOpen, setProductGroupPartsModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isQuote,setIsQuote] = useState(false);

  const { userFeedbackError, userFeedbackSuccess } = useAppContext();
  const navigate = useNavigate();
  const { setUserProfile } = useUser();

  const changeLearningGroupContext = async (learningGroupIri) => {
    const response = await requestApi.putResponse({
      url: 'learning-group-context',
      data: { learningGroup: learningGroupIri }
    });
    const decodedToken = jwtDecode(response.token);
    if (decodedToken) {
      setUserProfile(decodedToken);
      localStorage.setItem('accessToken', response.token);
      localStorage.setItem('refreshToken', response.refresh_token);
    }
  }

  useEffect(async () => {
    if (basket.owningLearningGroup) {
      await changeLearningGroupContext(basket.owningLearningGroup['@id']);
      const basketWithNewLearningGroupContext = await resourceApi.getResource({ apiEndpoint: 'baskets', id: basket.id });
      setBasket(basketWithNewLearningGroupContext);
    }
  }, []);

  useEffect(async () => {
    if (searchTerm) {
      if (searchGroupProducts) {
        const choices = await requestApi.getResponse({ url: `products/group-products-as-choices?search=${encodeURIComponent(searchTerm)}` });
        setProductOrProductVariantChoices(choices);
      } else {
        const choices = await requestApi.getResponse({ url: `products/products-and-product-variants-as-choices?search=${encodeURIComponent(searchTerm)}` });
        setProductOrProductVariantChoices(choices);
      }
    } else {
      setProductOrProductVariantChoices([]);
    }
  }, [searchTerm, searchGroupProducts]);

  // eslint-disable-next-line no-shadow
  const addProductsToBasket = async (productOrProductVariantIrisAndQuantities, formValues) => {
    if (!basket.id) {
      return;
    }

    const data = Object.keys(formValues).length > 0 ? toApiValuesCustom(formValues, fields) : {};
    data.basketLines = [];
    productOrProductVariantIrisAndQuantities.forEach((productOrProductVariantIriAndQuantity) => {
      if (productOrProductVariantIriAndQuantity.productOrProductVariantIri.match(/^\/product-variants/)) {
        data.basketLines.push({
          productVariant: productOrProductVariantIriAndQuantity.productOrProductVariantIri,
          quantity: productOrProductVariantIriAndQuantity.quantity || 1
        })
      } else {
        data.basketLines.push({
          product: productOrProductVariantIriAndQuantity.productOrProductVariantIri,
          quantity: productOrProductVariantIriAndQuantity.quantity || 1
        });
      }
    });
    const response = await axios(putRequestQuery(`baskets/${basket.id}/add`, data));
    if (response && response.data) {
      setBasket(response.data);
      userFeedbackSuccess(`${capitalise(entityName)} has been updated`);
    }
  };

  const handleDownloadBasketQuote = async () => {
    const response = await requestApi.getResponse({
      url: `baskets/${basket.id}/quote`,
      rawResponse: true,
    });
    const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
    if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
    const blob = new Blob([response.data], {type: 'application/pdf'});
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;
    return downloadLink.click();
  }

  const handleSubmit = async (values) => {
    const validateResponse = await axios(putRequestQuery(`baskets/${basket.id}/validate`, toApiValuesCustom(values, fields))).catch(error => ({ data: null, violations: error?.response?.data?.violations }));
    if (validateResponse.violations) {
      const { violations } = validateResponse;
      const violationsWithPropertyPath = [];
      // Allow orders to be placed even if validation fails for some propertyPaths eg. allow admin users to place orders under minimum order quantity
      const ignorePropertyPaths = ['product'];
      violations.forEach(violation => {
        if (violation.propertyPath && !ignorePropertyPaths.includes(violation.propertyPath)) violationsWithPropertyPath.push(violation);
      })
      if (violationsWithPropertyPath.length > 0) {
        // setApiErrors(violationsWithPropertyPath);
        userFeedbackError('There are validation errors in this form');
        return;
      }
    }

    const updatedValues = { ...values, isQuote: !!isQuote };

    const response = await resourceApi.saveResource({ apiEndpoint: 'baskets', id: basket.id, data: toApiValuesCustom(updatedValues, fields) });
    if (response.resource) {
      if (convertToOrder) {
        const convertResponse = await axios(putRequestQuery(`baskets/${basket.id}/convert`, toApiValuesCustom(updatedValues, fields))).catch(error => ({ data: null, violations: error?.response?.data?.violations }));
        if (convertResponse.data) {
          navigate(`/admin/orders/edit/${convertResponse.data.id}`);
        }
      } else {
        navigate(`/admin/quotes/edit/${basket.id}`);
      }
    } else if (response.violations) {
      const { violations } = response;
      violations.forEach(violation => {
        // Percolate validation errors from billingAddress/deliveryAddress up to firstName/lastName/phone
        if (violation.propertyPath === 'billingAddress.firstName' || violation.propertyPath === 'deliveryAddress.firstName') {
          const newViolation = { ...violation };
          newViolation.propertyPath = 'firstName';
          violations.push(newViolation);
        }
        if (violation.propertyPath === 'billingAddress.lastName' || violation.propertyPath === 'deliveryAddress.lastName') {
          const newViolation = { ...violation };
          newViolation.propertyPath = 'lastName';
          violations.push(newViolation);
        }
        if (violation.propertyPath === 'billingAddress.phone' || violation.propertyPath === 'deliveryAddress.phone') {
          const newViolation = { ...violation };
          newViolation.propertyPath = 'phone';
          violations.push(newViolation);
        }
      })
      setApiErrors(violations);
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={async (values) => handleSubmit(values)}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <>
          <Form formik={formik}>
            <Grid container mt={1} spacing={2} >
              <Grid item xs={12} >
                <UserInformation
                  formik={formik}
                  fields={fields}
                  getApiError={getApiError}
                  resource={resource}
                  basket={basket}
                  setBasket={setBasket}
                />
              </Grid>
              <Grid item xs={12} >
                <OrderItems
                  formik={formik}
                  getApiError={getApiError}
                  Autocomplete={Autocomplete}
                  basket={basket}
                  setBasket={setBasket}
                  toApiValuesCustom={toApiValuesCustom}
                  fields={fields}
                  entityName={entityName}
                  apiErrors={apiErrors}
                  setSearchTerm={setSearchTerm}
                  productOrProductVariantChoices={productOrProductVariantChoices}
                  setProductGroupPartsModalOpen={setProductGroupPartsModalOpen}
                  userFeedbackSuccess={userFeedbackSuccess}
                  productOrProductVariantIri={productOrProductVariantIri}
                  setProductOrProductVariantIri={setProductOrProductVariantIri}
                  searchGroupProducts={searchGroupProducts}
                  setSearchGroupProducts={setSearchGroupProducts}
                  resource={resource}
                />
              </Grid>
              {!resource.isQuote && (
                <Grid item xs={12} >
                  <ManualAdjustments
                    formik={formik}
                    fields={fields}
                    getApiError={getApiError}
                    entityName={entityName}
                    basket={basket}
                    setBasket={setBasket}
                    userFeedbackSuccess={userFeedbackSuccess}
                    resource={resource}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <BillingAddress
                  formik={formik}
                  getApiError={getApiError}
                  fields={fields}
                  resource={resource}
                />
              </Grid>
              <Grid item xs={12}>
                <DeliveryAddress
                  formik={formik}
                  fields={fields}
                  getApiError={getApiError}
                  resource={resource}
                />
              </Grid>
              {resource.isQuote && (
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Download Quote" />
                    <CardContent>
                      <Grid item xs={12}>
                        <MDButton color="info" onClick={handleDownloadBasketQuote} size="small" variant="gradient">
                          Download quote {basket.quoteReference}
                        </MDButton>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} >
                <Card>
                  <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                    { basket.id &&
                      <>
                        <MDButton
                          color="info"
                          disabled={formik.isSubmitting}
                          onClick={() => { setIsQuote(false); setConvertToOrder(true); setConfirmModalOpen(true); }}
                          variant="gradient"
                        >
                          {resource.isQuote ? 'Convert to' : 'Save'} Order
                        </MDButton>
                        {!resource.isQuote && (
                          <MDButton
                            color="info"
                            disabled={formik.isSubmitting}
                            onClick={() => { setIsQuote(true); setConfirmModalOpen(true); }}
                            variant="gradient"
                          >
                            Save As Quote
                          </MDButton>
                        )}
                      </>
                    }
                    <MDButton
                      color="secondary"
                      disabled={formik.isSubmitting}
                      onClick={() => navigate(`/admin/${entityName === 'quote' ? 'quotes' : 'orders'}`)}
                      sx={{ m: 1, mr: 'auto' }}
                      variant="gradient"
                    >
                      Cancel
                    </MDButton>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Form>
          {
            productGroupPartsModalOpen && (
              <Dialog
                open
                fullWidth
                maxWidth={false}
              >
                <DialogTitle>Products in group</DialogTitle>
                <DialogContent>
                  <AddOrderProductGroupParts
                    addProductsToBasket={addProductsToBasket}
                    productIri={productOrProductVariantIri}
                    setModalOpen={setProductGroupPartsModalOpen}
                  />
                </DialogContent>
              </Dialog>
            )
          }
          {
            confirmModalOpen && (
              <Dialog open>
                <DialogTitle>{resource.isQuote && !isQuote ? 'Convert' : 'Save'} {isQuote ? 'quote' : 'order'}?</DialogTitle>
                <DialogContent>
                  {resource.isQuote && !isQuote ? 
                    'Are you sure you want to convert this order into a quote?' :
                    `Are you sure you want to save this ${isQuote ? 'quote' : 'order'}?`
                  }
                </DialogContent>
                <DialogActions>
                  <MDButton
                    color="info"
                    disabled={formik.isSubmitting}
                    onClick={(e) => { setConfirmModalOpen(false); formik.handleSubmit(e); }}
                    variant="gradient"
                  >
                    Yes
                  </MDButton>
                  <MDButton
                    color="secondary"
                    disabled={formik.isSubmitting}
                    onClick={() => { setConfirmModalOpen(false); }}
                    variant="gradient"
                  >
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            )
          }
        </>
      )
      }
    </Formik>
  );
};

AddOrderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  apiErrors: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  setApiErrors: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

AddOrderForm.defaultProps = {
};

export default AddOrderForm;
