import { useRef } from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  FormHelperText,
  Typography,
} from '@mui/material';

import { Editor } from '@tinymce/tinymce-react';

// Wiris MathType for TinyMCE for MathML support
require('@wiris/mathtype-tinymce6');

const RichText = (props) => {
  const {
    enableMathML,
    formik,
    getApiError,
    label,
    name,
    showFileBrowser,
  } = props;

  const richTextEditorRef = useRef(null);

  const toolbarParts = [];
  toolbarParts.push('undo redo');
  toolbarParts.push('blocks');
  toolbarParts.push('bold italic underline');
  toolbarParts.push('bullist numlist');
  if (enableMathML) {
    // MathML support from MathType
    toolbarParts.push('tiny_mce_wiris_formulaEditor blockquote');
  } else {
    toolbarParts.push('blockquote');
  }
  toolbarParts.push('alignleft aligncenter alignright');
  toolbarParts.push('link image');
  toolbarParts.push('removeformat');
  toolbarParts.push('code fullscreen');

  return (
    <>
      <Typography variant="h6">
        {label}
      </Typography>
      <Editor
        initialValue={getIn(formik.values, name)}
        onInit={(event, editor) => { richTextEditorRef.current = editor; }}
        onBlur={() => {
          if (richTextEditorRef.current) {
            formik.setFieldValue(name, richTextEditorRef.current.getContent());
          }
        }}
        init={{
          link_rel_list: [
            { title: 'Follow', value: 'follow' },
            { title: 'No Follow', value: 'nofollow' },
          ],
          height: 300,
          menubar: false,
          plugins: 'lists link image code fullscreen',
          external_plugins: {
            tiny_mce_wiris: '/node_modules/@wiris/mathtype-tinymce6/plugin.min.js',
          },
          toolbar: toolbarParts.join(' | '),
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          inline_styles: false,
          formats: {
            underline: { inline: 'u', exact : true }
          },
          // eslint-disable-next-line no-shadow
          file_picker_callback: (callback, value, meta) => {
            if (meta.filetype === 'image') {
              if (showFileBrowser) showFileBrowser(callback);
            }
          },
        }}
      />
      {Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))) && (
        <Box sx={{ mt: 2 }}>
          <FormHelperText error>
            {getApiError(name) || getIn(formik.errors, name)}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};

RichText.propTypes = {
  enableMathML: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showFileBrowser: PropTypes.func,
};

RichText.defaultProps = {
  enableMathML: false,
  showFileBrowser: null,
};

export default RichText;
